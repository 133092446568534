@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
figure.snip1390 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 100%;
  border-radius: 10px;
  width: 100%;
  color: #000000;
  text-align: center;
  font-size: 16px;
  background-color: var(--newPurple);
  /* padding: 30px; */
  padding: 2% 1% 1% 1%;
  background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin-block-start:0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  
  
}
figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
figure.snip1390 figcaption {
  width: 100%;
}
figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 0;
}
figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}
figure.snip1390 h2 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.1rem;
}
figure.snip1390 h4 {
  color: #a6a6a6;
}
figure.snip1390 blockquote {
  font-size: 0.9em;
  /* padding: 3% 1% 2% 4% */
  padding: 45px 20px 40px 50px;
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}
figure.snip1390 blockquote:before {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 70px;
  opacity: 0.25;
  font-style: normal;
  top: 0px;
  left: 20px;
}
figure.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: solid 5px var(--white);
  display: none;
}
.testimonialItem{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.tcardBody{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.slide-top , .container-fluid{
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@media only screen and (max-width:480px) {
figure.snip1390{
  max-width: 100%;
}
}