nav {
  height: 95px;
  /* height: 85px; */
  display: flex;
  justify-content: space-between;
  /* padding: 1rem 2rem; */
  z-index: 100;
  position: fixed;
  left: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 1px 0px 4px 2px #888888;
  top: 51px;
}
.logo{
  /* width: 26%;
  margin-left: 15%; */
  width: 14%;
  margin-left: 13%;
}
.link {
  text-decoration: none;
  display: flex;
  color: var(--black);
  align-items: center;
  /* font-size: 1.2rem; */
  font-size: 16px;
  /* font-size: 1.2rem; */
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: bold;
}
.link:hover{
  color: purple;
    font-weight: bolder;
}
.menu-items .link{
  padding: 0 2rem; 
}
.mobile-menu-icon {
  display: none;
}
.icons {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2% 0%;
    margin-top: -5px;
    box-shadow: 0px 1px 5px 1px rgb(19 2 30 / 25%);
    /* filter: brightness(0.5); */
    border-radius: 9px;
    background: #fbfbfb;
}
.icon-tabler {
  margin-right: 5px;
}
.github-icon {
  color: white;
}
.menu-items {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .menu-items,
  .signup-button,
  .github-corner {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
    color: var(--primary);
    display: flex;
    align-items: center;
    font-size: 4vh;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}

.github-corner {
  position: fixed;
  top: 0;
  z-index: 99999;
  right: 0px;
}

.menuIcon{
  color: var(--newPurple);
  font-size: 1.3rem;
  padding-right: 15%;
}

@media only screen and (max-width:480px) {


nav {
  height: 70px;
  padding: 0% 0% 0% 2%;
}

.link{
  padding: 0;
}
.logo{
  width: 30%;
}
.icons{
  margin: 10px;
}
.logo {
  /* width: 26%; */
  margin-left: 7%;
}

}