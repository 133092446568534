.contact {
    display: flex;
    display: flex;
    width: 100%;
    justify-content: space-around;
    /* background-color: var(--white); */
    /* margin-top: 2%; */
    font-weight: bold;
    font-size: 80%;
    border-radius: 10px;
}

.phone,
.email,
.address {
    /* width: 20%;
    background-color: var(--primary);
    margin: 3% 0%;
    padding: 0% 0% 2% 0%;
    border-radius: 6px;
    color: var(--white);
    box-shadow: 0 0 9px 4px rgb(0 0 0 / 10%); */
    width: 30%;
    background-color: var(--newPurple);
    /* margin: 3% 0%; */
    margin: 3% 0% 1% 0%;
    /* padding: 0% 0% 2% 0%; */
    border-radius: 6px;
    color: var(--white);
    box-shadow: 0 0 9px 4px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.address>span {
    line-height: 2;
    padding-bottom: 2%;
}

.footerIcon {
    padding: 10px;
    color: purple;
    background-color: white;
    border-radius: 7px;
    width: 56px;
    height: 56px;
    font-size: 2rem;
    position: relative;
    top: -17%;
    left: 41%;
    border: 1px solid;

}

.footerBar {
    /* background-color: var(--newPurple); */
    /* height: 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: var(--white);
    grid-gap: 37px;
    /* gap: 37px; */
    /* padding-top: 3%;*/
    padding-bottom: 1%; 
}

.footerBar span {
    border-right: 1px solid white;
    height: 10px;
    padding-left: 32%;
}

.contact a {
    color: white;
}

/* iframe {
    border-radius: 10px;
} */

.footerContainer {
    /* padding-top: 1%; */
    /* background-image: url(https://cdn.wallpapersafari.com/32/21/EwH8Up.jpg); */
    margin-top: 1%;
}
.map{
    padding: 0% 2%;
}
.white{
    color: white !important;
}
iframe{
    border-radius: 10px;
}

@media only screen and (max-width:480px) {
    .contact {
        flex-direction: column;
    }

    .phone,
    .email,
    .address {
        width: 96%;
        margin: 5% 2% 3% 2%;

    }

    .footerIcon {
        width: 40px;
        height: 40px;
        font-size: 1rem;
        margin-top: -4%;
    }
}