.slide-container {
  padding-top: 9%;
}

body {
  text-align: center;
  /* padding: 30px; */
  background: #f8f4f2;
  font-family: Arial;
}

.a-box {
  display: inline-block;
  width: 300px;
  /* width: 240px; */
  text-align: center;
  padding-bottom: 2%;
}
.sliderImage{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 550px;
}
.countryBox {
  cursor: pointer !important;

}

.img-container {
  height: 230px;
  width: 275px;
  /* width: 200px; */
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  /* transform: skew(0deg, -13deg); */
  height: 250px;
  margin: -35px 0px 0px -110px;
  width: 174%;
  /* margin: -35px 0px 0px -70px; */
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  /* transform: skew(0deg, 13deg); */
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 250px;
  width: 275px;
  /* width: 200px; */
}

.text-container {
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  color: #fff;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 14px;
  background-color:#e1e5ea; */
  /* background-color: var(--primary); */
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    padding: 120px 20px 8px 20px;
    border-radius: 20px;
    color: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 4px;
    font-size: 14px;
    background-color: #e1e5ea;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: var(--black);
  font-size: 18px;
}


/* //university */
.containerUniversity {
  background-color: var(--white) !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mt7 {
  padding-top: 11% !important;

}


.container {
  display: flex;
  width: 1040px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
  overflow: hidden;
  width: 317px;
  /* width: 300px; */
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* gap: 45px; */
  padding-top: 2%;
}

.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  /* min-height: 250px; */
}

.card-body h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.tag-teal {
  background-color: var(--primary)
}

.tag-purple {
  background-color: #5e76bf;
}

.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}

.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.user-info h5 {
  margin: 0;
}

.user-info small {
  color: #545d7a;
}

@media only screen and (max-width:480px) {
  .slide-container {
    padding-top: 29%;
  }
  .a-box{
    width: 95%;
  }
  .sliderImage{
    height: 200px;
  }
  .mt7{
    padding-top: 35% !important;
  }
  .containerUniversity {
    background-color: var(--white) !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}
.card {
  width: 43%;
}
}