* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

#root {
  margin: 0;
  /* background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, blue 100%); */
  /* background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%); */
  color: var(--black);
  background-color: var(--white)
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --primary: #800080;
  --white: white;
  --black: black;
  --newPurple: #4c00a4;
}

body {
  background-color: #f7f8fc;
  /* background-color: #cfcaca0d; */

}

.mobileItem {
  display: none;
}

.containerMargin {
  margin: 2% 7% !important;
}

.universityImage:hover {
  opacity: .5;
  transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
}

a {
  /* color: var(--white); */
  text-decoration: none;
  cursor: pointer;
}

.typewriter div {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  /* Adjust as needed */
  animation:
    typing 6s steps(80, end) infinite,
    blink-caret .75s step-end infinite;
}

.manpowerMargin {
  padding-top: 11% !important;
}

.manpowerMarginHome {
  padding-top: 2%;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 40%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: orange;
  }
}

.h1 {

  font-size: 1.8rem;
  color: black;
  /* color: white; */
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  /* text-shadow: 2px 2px 1px rgb(9 9 9 / 60%); */
  text-shadow: 2px 2px 1px rgb(220 202 202 / 60%);
  text-transform: uppercase;

}

.bgTopCountries,
.manTopCountreis,
.bgValues {
  background-color: #abb8c340;
  /* background-color: gray; */
  padding: 3% 0%;
  /* background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%); */
  /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); */
}

.bgContact {
  background-color: var(--white);
  /* background-image: url(https://cdn.wallpapersafari.com/32/21/EwH8Up.jpg); */

  background: url(https://cdn.wallpapersafari.com/32/21/EwH8Up.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  color: white;
  /* background-color: gray; */
  /* padding: 3% 0%; */
  /* background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%); */
}

.bgTestimonials,
.manService {
  background-color: white;
  /* background-color: gray; */
  padding: 3% 0%;
  /* background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%); */
}

@media (min-width:320px) {}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
}

/* @media only screen and (min-width: 480px) {
  .desktop {
    display: block;
  }
  .mobile, .tablet {
    display: none;
  }
}


Mobile Query*/
@media only screen and (max-width:480px) {
  .h1 {
    font-size: 1.2rem;
    margin: 2%;
  }

  .typewriter div {
    animation: none;
  }

  .switch-button {
    font-size: 14px;
  }

  .manpowerMargin {

    padding-top: 41% !important
  }

  #root {
    /* background-image: linear-gradient(260deg, #4158D0 0%, #C850C0 46%, blue 100%); */
  }

  .marginBottonTopCountries {
    margin-bottom: 4%;
  }

  .marginTestimonial {
    margin-top: 5%;
  }

  .react-slideshow-container .default-nav {
    height: 15px;
    width: 10px;
  }
}