// * {
//     box-sizing: border-box;
//     padding: 0;
//     margin: 0;
//   }
  
//   body {
//     background-color: #155FFF;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100vh;
//     font-family: sans-serif;
//   }
.service{
    display: flex;
    justify-content: center;
}
  
  .switch-button {
    background: var(--primary);
    // background: rgba(88, 33, 33, 0.56);
    border-radius: 30px;
    overflow: hidden;
    width: 240px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    color: var(--white);
    position: relative;
    padding-right: 120px;
    position: relative;
  
    &:before {
      content: "Job";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }
  
    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
  
      &:checked + .switch-button-label:before {
        transform: translateX(120px);
        transition: transform 300ms linear;
      }
  
      & + .switch-button-label {
        position: relative;
        padding: 15px 0;
        display: block;
        user-select: none;
        pointer-events: none;
  
        &:before {
          content: "";
        //   background: rgb(7, 228, 44);
          // background: #00AB66;
          background: #01844f;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 30px;
          transform: translateX(0);
          transition: transform 300ms;
        }
  
        .switch-button-label-span {
          position: relative;
        }
      }
    }
  }
  